import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Inputs come in two different colors. The default input color is `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-background`}</inlineCode>{` and `}<inlineCode parentName="p">{`$ui-02`}</inlineCode>{` page backgrounds. The `}<inlineCode parentName="p">{`--light`}</inlineCode>{` version input color is `}<inlineCode parentName="p">{`$field-02`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` page backgrounds.`}</p>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/components/text-input"
      }}>{`text input`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/dropdown"
      }}>{`dropdown`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/checkbox"
      }}>{`checkbox`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/radio-button"
      }}>{`radio button`}</a>{`, `}<a parentName="p" {...{
        "href": "/component/toggle"
      }}>{`toggle`}</a>{`, and `}<a parentName="p" {...{
        "href": "/component/button"
      }}>{`button`}</a>{` pages for specific typography details on each component.`}</p>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.04347826086957%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAAvklEQVQoz62QSwqDMBRFk75NFEq3mqk7cFYkBtyLG1ER/yaCA7VXiSLUWge9cDjBmMtLGLNxHGexEIK5rsuMMaxtW9Y0zeIj5qz+yDRNm8EtSRJK05SqqiIcIhRvtuvzwn3p1Witv2/GccytHyDI81wBWde1jylWJFDAA087IT8sLMuS2x/uuOoriiKFYpllmY89vyiKGQkU8MBSCPO/XXkYht/vN7vve8L7UNd1NI4j4dsR50OEYbj5Kvtza95v8OvwxcNSxAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Example of form fields in the two colors",
          "title": "Example of form fields in the two colors",
          "src": "/static/89c118d135a93abadda7f80759d34c03/fb070/form-style-3.png",
          "srcSet": ["/static/89c118d135a93abadda7f80759d34c03/d6747/form-style-3.png 288w", "/static/89c118d135a93abadda7f80759d34c03/09548/form-style-3.png 576w", "/static/89c118d135a93abadda7f80759d34c03/fb070/form-style-3.png 1152w", "/static/89c118d135a93abadda7f80759d34c03/c3e47/form-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Example of light fields with $field-02 (left) and the default version with
  $field-01 (right)
    </Caption>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Form headings and labels should be set in sentence case with the first letter of the first word capitalized. Refer to the `}<a parentName="p" {...{
        "href": "/components/text-input"
      }}>{`text input`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/dropdown"
      }}>{`dropdown`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/checkbox"
      }}>{`checkbox`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/radio-button"
      }}>{`radio button`}</a>{`, `}<a parentName="p" {...{
        "href": "/component/toggle"
      }}>{`toggle`}</a>{`, and `}<a parentName="p" {...{
        "href": "/component/button"
      }}>{`button`}</a>{` pages for specific typography details on each component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28 / 1.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input::placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form__helper-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$helper-text-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`Forms are made up of several different components. The order in which these elements are arranged is flexible, but should always follow the spacing guidelines below.`}</p>
    <p>{`Forms can be one column or two. The width of each column varies based on the content and layout of the design. On mobile, forms can only have one column.`}</p>
    <p>{`All icons can be found on the `}<a parentName="p" {...{
        "href": "/guidelines/iconography/library"
      }}>{`iconography`}</a>{` library page.`}</p>
    <h3 {...{
      "id": "recommended"
    }}>{`Recommended`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Element`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 / 1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-06`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Title area`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gutter between items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Buttons`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "83.48214285714286%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAADT0lEQVQ4y6WU32scVRTH7zSF4kMRhE0b/4Lqm8+++iI++AvFVyGCFgqCVBG06IMIKT74UKhWMKmVNkYCRVr7Q2x+mCxJrG5NILY22t3uZGfnzs7cX/PzTuZ47ky2u5oXwQvfPWcv937uOd+5M4T8z3HKtv858eatBiHH37HIxNv7JjbvP/Jlh45eELw2LWXtvBBV5Lw26bq1z9rt2ud2u3bGtk1+6GSr9ZBhfNJqDYAzToccHH95hJz+5sDSdnNu/d6fO7cdh29RKreoK++6rjT5Pd+XbcHlfc6lzTlrSgE/et64YSz2eiMPgJfatIwAQC5fdFe9pgTqOeC5FHrUK2Pg+6CEhCgMIVIhKC7AjJ/93lGzt+H7+x8ALzQrIIEnreVNUVcKgMe55mFesFAXTOkiMJJZ4Yu08GW6w2SmVQHwQ4e9brYuOHxQ4fRffeBz1twaqwueg+vz3KEMvEABkwkELAQPVeYiLpiIdYTAG05QApe6bFDhFdoHbli/d7264hw8j+ZoE8TYYpjEgN4BC3yQ6FuaJkWWJNq0fJNVwN/4EPC77T7wNWulwepZmEOURHmcJJDGMQRRBI0gAIkHKeNhHBdJHGssEFaDCvgrGwJe6vaBr1obLV7P4gw3RXmWZYA/INMUNhgDZh4MGozzhc6yssI1Vnl4i/OhCu0+cN1aWvPq3FPgUhdb5iCEQP8YbGOF5r+RVKoIldI5Ald8vwT+MvyUB0Cwbix36tRh0LLtvOt60MVr43QpKCnRv0p4SKGE0KbElV5vL/Cq16uShSespivqAObsENcbm7LCxExnRYqtmnYx7mitd1uuPGzwoWvz1R17t0BCPj4drU5dAzi3nMDZRQ1TCxrOL2sIVQwan3YWV0rNZS0vtv9G6eFwheYNIeQI6cD4gaOX+dyLk6l+fjJhz3yRiKfPJPyFqYSv9zjfEpzfQRP/4Ezc5TzYRD+/p7R89a57dFAhZFAlz54Ymb09OwaweAhgYRSF8dqY0amri4dPXpwfm7iydPij+bXRifmbo+9f/+nRl85OP2y2vvL1zH/7NL1FyMinhOzHI/dBFfeMp+Bfs6blY+RxQr49QY5h/iHqA9S7sHf7Yzg3i5pBnUMd313z3tDavwFEwAOYZTu3+gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for a double column form ",
          "title": "Structure and spacing measurements for a double column form ",
          "src": "/static/c84ae7e1e1ffc3b8461009d973b28a90/fb070/form-style-2.png",
          "srcSet": ["/static/c84ae7e1e1ffc3b8461009d973b28a90/d6747/form-style-2.png 288w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/09548/form-style-2.png 576w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/fb070/form-style-2.png 1152w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/fb104/form-style-2.png 1728w", "/static/c84ae7e1e1ffc3b8461009d973b28a90/8fefe/form-style-2.png 2240w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for a double column form | px / rem
    </Caption>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.16071428571428%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAACI0lEQVQ4y5WUzWsTQRjGp6J/hODBP8FrBcWLUAQ99qYH8aBVPAki4qEuerKtoFJQwWp70Fvxo1hTP7D5rDQBNTaQkDRWs83uzM7Mzn5lNt3X/bgJutsXlmfggR/PM7MzCP0916/GAgDof/PBMFD63LgWy9nFxb2RPm+2T662Nx9Wt9WpOqUz3wm5WzWMmRf9/sHIf6lpI5mAb5uNfZEW1a2pnTCqjgkIx4EgXHvht6zrRyO/QMie1JCH5ufQWp7HCV/l+pPdhgUY9xysaVIw6mOTyxWsj0Z+2UgDhgnP19ZRecOOge++coUJAOH6kll+YFo+/KRu8KZPDkf+Z52mJzxTKqDVqhUDX1eoQs2wMhWyp/EAGzZ0DScE4t0BWxqLgdUeUdyBD0TXJGM0cCwBzHWCFZIA11hGYK2eVP5U44oTVjZNJh3HDaTnAbbsIEeSymWaEfjtlxoDC5tbiuUNgFFDMs4DSwjoh5rDScJSllOOgPlaNwYufWkrqsrBMIjkpgiEacI22w0wPOXTxTza6IoYWGoZys4QwLa4HHhuMJQeUNcO9zCpXMmyh+cqJVRZ95LfpmhNar8BmLBcZnq+EIOhym0/h8lokpCmJzzx8T269FTGwIkF8874HMCpRwBjswDHHwCMLwA865AjkT9dzQDcP/8ELdnD+I7earaOPe7QK7M/+IV7dX7xft2cmK7jyzcbzQORf7vRGUFZJ+21Kf/D/wO6O50fNdfHAAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for a single column form",
          "title": "Structure and spacing measurements for a single column form",
          "src": "/static/a6c6128e315b1044f2a79981b3a852c2/fb070/form-style-1.png",
          "srcSet": ["/static/a6c6128e315b1044f2a79981b3a852c2/d6747/form-style-1.png 288w", "/static/a6c6128e315b1044f2a79981b3a852c2/09548/form-style-1.png 576w", "/static/a6c6128e315b1044f2a79981b3a852c2/fb070/form-style-1.png 1152w", "/static/a6c6128e315b1044f2a79981b3a852c2/fb104/form-style-1.png 1728w", "/static/a6c6128e315b1044f2a79981b3a852c2/8fefe/form-style-1.png 2240w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing measurements</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      